
//import logo from './logo.svg';
//import './App.css';
import React, { useEffect, useState } from 'react';
import Selector from './components/Selector';
import ChipBar from './components/ChipBar';
import Home from './pages/Home';
import DataView from './pages/DataView';
import Layouts from './pages/Layouts';
import {Box, 
        Container, 
        Typography, 
        Input,
        Paper,
        InputLabel,
        MenuItem,
        Select,
        Button } from '@mui/material';
import { createTheme ,ThemeProvider} from '@mui/material/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import HEX from './libs/hex.js';
import { DataGrid } from '@mui/x-data-grid';
import {BrowserRouter as Router,
        Route,
        Routes,
        Link} from 'react-router-dom';



import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDOw8vHU0Lowv4EQoC1IQ7Ngo9G56BoCto",
  authDomain: "dev-stepayne.firebaseapp.com",
  projectId: "dev-stepayne",
  storageBucket: "dev-stepayne.appspot.com",
  messagingSenderId: "730288844337",
  appId: "1:730288844337:web:0974f3c224f4c82605ef10",
  measurementId: "G-S43JW06KVW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#3f3f3f',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "tomato"
        }
      }
    }
  }
});



function App() {

  
  return (

    <ThemeProvider theme={darkTheme}>
       <CssBaseline />
    <div className="App xcard" >
      <Paper
       sx={{
        bgcolor: 'background.default',
        color: 'text.primary',
        borderRadius: 1,
        p: 3,
       }}>
       <Link 
          to='/' 
          style={{textDecoration:'none'}}
          sx={{
            bgcolor: 'background.default',
            color: 'text.primary',
            borderRadius: 1,
            p: 3,
           }} 
        >
          <Button variant='contained' style={{margin:'2px'}}>Home</Button>
       </Link>
       <Link to='/dataview' style={{textDecoration:'none'}}><Button variant='contained' style={{margin:'2px'}}>Data View</Button></Link>
     
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/dataview/layouts' element = {<Layouts/>} />
          <Route path='/dataview' element={<DataView/>}/>
          
        </Routes>
     </Paper>
    </div>
    </ThemeProvider> );
    
  
}



export default App;
