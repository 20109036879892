/*
Script to convert Hex fields to ascii
*/


const HEX = ( () =>{
    let _data = '';
    let _layout = '';
    let _recLength = 0;
    let _regEx = '';



    const hexTable = {
        "40": " ",
        "5C": "*",
        "60": "-",
        "C0": "{",
        "C1": "A",
        "C2": "B",
        "C3": "C",
        "C4": "D",
        "C5": "E",
        "C6": "F",
        "C7": "G",
        "C8": "H",
        "C9": "I",
        "D1": "J",
        "D2": "K",
        "D3": "L",
        "D4": "M",
        "D5": "N",
        "D6": "O",
        "D7": "P",
        "D8": "Q",
        "D9": "R",
        "E2": "S",
        "E3": "T",
        "E4": "U",
        "E5": "V",
        "E6": "W",
        "E7": "X",
        "E8": "Y",
        "E9": "Z",
        "F0": "0",
        "F1": "1",
        "F2": "2",
        "F3": "3",
        "F4": "4",
        "F5": "5",
        "F6": "6",
        "F7": "7",
        "F8": "8",
        "F9": "9"
    };
    const hexTableNum = {
        "F0": "0",
        "F1": "1",
        "F2": "2",
        "F3": "3",
        "F4": "4",
        "F5": "5",
        "F6": "6",
        "F7": "7",
        "F8": "8",
        "F9": "9",
        "C0": "0+",
        "C1": "1+",
        "C2": "2+",
        "C3": "3+",
        "C4": "4+",
        "C5": "5+",
        "C6": "6+",
        "C7": "7+",
        "C8": "8+",
        "C9": "9+",
        "D0": "0-",
        "D1": "1-",
        "D2": "2-",
        "D3": "3-",
        "D4": "4-",
        "D5": "5-",
        "D6": "6-",
        "D7": "7-",
        "D8": "8-",
        "D9": "9-"
    };

    return {
        //initialize the converter
        init: function (data, layout) {
            if (data===undefined){
                return;
            }
            //strip spaces and new lines from raw data
            _data = data.replaceAll(/[\s\n\r]/g, '');
            _layout = layout;
            let regExString = '';
            let fieldLen = 0;
            _recLength = 0;
            for (let field of layout) {
                if (!field.compressed) {
                    fieldLen = field.len * 2;
                } else {
                    //compressed fields still need to be an even number of characters
                    fieldLen = field.len % 2 + field.len;
                }
                regExString += `(.{${fieldLen}})`;
                _recLength += fieldLen;
            }
            //compile the regex 
            _regEx = new RegExp(regExString, 'gi');
        },

        // iterator to return a converted line on each call

//offset take initial offset and add previous record length

        lines: function* () {
            const lines = _data.matchAll(_regEx);
            //break the text into lines
            for (let line of lines) {
                let returnData = {};
                console.log(`${line}`);
                //loop through the matches - 0 is the entire string positions 1 to length is the individual fields
                for (let x = 1; x < line.length; x++) {
                    let tString = "";
                    if (!_layout[x - 1].compressed) {
                        //break the value into pairs of chars
                        let chars = line[x].match(/(.{2}|.{1})/g);
                        //look up the hex value on the alpha or numeric table
                        let char;
                        //Loop through the pairs of characters
                        for (let hexChar of chars) {
                            //look the pair of characters up in either the char or number tables
                            _layout[x - 1].type === 'C' ? char = hexTable[hexChar] : char = hexTableNum[hexChar];
                            //if we do not find a match return ? and output to console
                            if (char === undefined) {
                                console.log(`undefined char encountered ${hexChar}`);
                                char = '?';
                            }
                            //add our char to the value
                            tString += char;
                        }
                    } else {
                        //its a number - output the hex values
                        tString = line[x];
                    }
                    console.log(`${_layout[x - 1].name} - ` + tString);
                    //build the return payload
                    let decipher = "";

                    if (_layout[x - 1].hasOwnProperty("lookup")){
                        decipher = _layout[x - 1].lookup[tString];
                    }
                   
                    const retObj = {
                        layout: _layout[x - 1],
                        value: tString,
                        hex: line[x],
                        offset: 0,
                        desc: decipher,
                    };
                    returnData[`${_layout[x - 1].name}`] = retObj;
                }
                yield returnData;
            }
        }
    };
})();

export default HEX;

