import React, { useEffect, useState } from 'react';
import Selector from '../components/Selector';
import ChipBar from '../components/ChipBar';
import '../styles/style.css';
import {ErrorBoundary} from 'react-error-boundary';

import { 
        Paper,
        Container, 
        Typography, 
        Card,
        InputLabel,
        MenuItem,
        Select,
         } from '@mui/material';
import { createTheme ,ThemeProvider} from '@mui/material/styles';
import HEX from '../libs/hex.js';
import { DataGrid } from '@mui/x-data-grid';




function MyGrid({hexData,layout}) {
 
  if (hexData===undefined || layout===undefined){
    return;
  }
  HEX.init(hexData, layout.fields);
  const iter = HEX.lines();
  let rowID = 0;
  const columns = [];    
  const rows = [];    
  let tmpRow = {};
  
  for (let data of iter) {
    rowID += 1;
    if (rowID === 1){
      columns.push({ field: 'id', headerName: 'ID', width: 70 , sortable: true, filter: true });
      for (let value in data) {
        console.log(value);
        columns.push({field:value, headerName:value, width:70});
      }
    }
    tmpRow = {id:rowID};
    for (let field in data) {
        tmpRow[field] = data[field].value;
        console.log(data[field].value);
    };
    rows.push(tmpRow);
  };
  
  return (
    <Card style={{ height: 600, width: '100%', display:'flex' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20]}
        checkboxSelection
      />
    </Card>
  
  );
  }
 
  function HexView({rawData, changeFunc}){
    const change = (e)=>{
      changeFunc(e.target.value);
    }
     return (
     <textarea id="txtArea" style={{display:'flex'}} onChange={change} className="form-control" rows="20" cols="40" defaultValue={rawData}>
       </textarea>);
 }

export default function DataView(props){
    const [rawData,setrawData] = useState(' ');
    const rawDataChanged = (d) => {
      setrawData(d);
      console.log(`useState ${rawData}`);
    };
   
    const vals = JSON.parse(localStorage.getItem("savedOptions"));
    const [options,setOptions] = useState(vals==null?[]:vals);
    const [selectedOption, setSelectedOption] = useState(undefined);
  
    useEffect(()=>{
        console.log(selectedOption);
      },[selectedOption]);
      

    const updateOptions = (newOptions)=>{
        setOptions(newOptions);
  
        localStorage.setItem('savedOptions',JSON.stringify(newOptions));
    };
  
    const updateSelectedOption = (index)=>{
      setSelectedOption(index);      
    };
   
    return (
      <div className="App">
          <Paper className='xcard'>
            <ChipBar options = {options} 
                     selectedIndex={selectedOption}
                     onSelectFunc={(x)=>{updateSelectedOption(x)}} 
                    onModifyFunc ={(x)=>{updateOptions(x)}}  
                    className='xcard'
            />
            {/*
            <Selector options = {options} 
                  selectedIndex={selectedOption} 
                  onSelectFunc={(x)=>{updateSelectedOption(x)}} 
                  onListModifyFunc ={(x)=>{updateOptions(x)}}
                  className='xcard' 
            />
            */}
          </Paper>
          <HexView rawData={rawData} changeFunc = {rawDataChanged} />
          {selectedOption===undefined?
                                      <></>
                                      :<MyGrid hexData = {rawData} layout={options[selectedOption]} > </MyGrid>}                     
      </div>
    );
};
