import { Chip , Stack, Paper, CircularProgress } from "@mui/material";
import {AddCircle} from "@mui/icons-material";
import {useRef, useState} from 'react';
import '../styles/style.css';


function ChipBar({options, selectedIndex, onSelectFunc, onModifyFunc, fileFilter}){

    const inputFile = useRef(null);
    const formRef = useRef(null);
    const [isWorking, setIsWorking] = useState(false);

    function processFileChange(e){
        setIsWorking(true);  
        const fr = new FileReader();
        fr.onload = () => {  
            let d = JSON.parse(fr.result);
   //         setSelectVal(d.hasOwnProperty('Title')?d.Title:'invalid file');
          
          //  setSelectVal('updated');
            let newList = options;
            newList.push(d);
            
            onModifyFunc(newList);
            d.hasOwnProperty('Title') ? onSelectFunc(d.Title) : alert("invalid");

            setTimeout(  ()=>{
                setIsWorking(false)  
              },  500
            ); 
        };
        console.log(fr.result);
        fr.readAsText(e.currentTarget.files[0]);
        
    }

    
    const onDeleteFunc = (opt, index)=>{
        let newOptions = options.filter((o,i) => i!==index);
        onModifyFunc(newOptions);
        if (selectedIndex === index){
            onSelectFunc(undefined);
        } else if (selectedIndex > index){
            onSelectFunc(selectedIndex -1);
        }
            
    };

    const onClickFunc = (opt, index)=>{
        onSelectFunc(index);
    };

    const onAddClickFunc = (opt, index)=>{
        inputFile.current.click();
        inputFile.current.value = null;
        formRef.current.reset();
       // alert(chipRefs.current[chipRefs.current.length-1].Title);
        if (chipRefs.current.length > 0){
            chipRefs.current[chipRefs.current.length-1].focus()
        }
    };

    const chipRefs = useRef([]);

    const getChipRef = (index, el) =>{
        chipRefs.current[index] =el ;
        return chipRefs.current[chipRefs.current.length -1]
    }

    return(
        <Paper>
            
            <Stack direction ='row' spacing={1} style={{display:'flex', flexWrap:'wrap', padding:'10px'}}> 
                    <Chip 
                        key={-1} 
                        deleteIcon = {<AddCircle/>}  
                        label={'Add'}  
                        onDelete={()=>{onAddClickFunc()}}
                        onClick={()=>{onAddClickFunc()}}
                        className={'card'}
                        style={{margin:'2px'}}
                    /> 
                    <div>
                      <form ref={formRef}>
                        <input
                            type="file"
                            id="file"
                            ref={inputFile}
                            style={{ display: "none" }}
                            accept={fileFilter}
                            onChange={processFileChange}  
                        />
                      </form>
                    </div>
                    {       
                                      
                    options.map((opt, index) => {
                        return [
                        <Chip 
                            className={'card'}
                            key={index} 
                            value={index} 
                            ref={(index)=>getChipRef}
                            label={opt.Title} 
                            onDelete={()=>{onDeleteFunc(opt,index)}}
                            variant={index === selectedIndex?undefined:"outlined"}
                            color={index===selectedIndex?"secondary":"primary"}
                            onClick={()=>{onClickFunc(opt, index)}}
                            onFocus={()=>(console.log(`chip ${index} has focus`))}
                        />]  
                        }
                    )
                }              
                                
            </Stack>
        </Paper>
    )
}

export default ChipBar;
