export default function Layout(){

	let html = `
 
	{
		"record": "SINMCREC",
		"Title": "RO tech logging record",
		"Description": "RO log record - shows time worked against RO",
		"offset": 125,
		"len": 25,
		"fields": [{
				"name": "Tech",
				"type": "C",
				"len": 3
			},
			{
				"name": "RO",
				"type": "C",
				"len": 5
			},
			{
				"name": "Skill",
				"type": "C",
				"len": 2
			},
			{
				"name": "Timestamp",
				"type": "TS",
				"len": 11,
				"compressed": true
			},
			{
				"name": "Line",
				"type": "9",
				"len": 2
			},
			{
				"name": "Action",
				"type": "C",
				"len": 2,
				"lookup": [{
						"GH": "Go Home"
					},
					{
						"W ": "Working"
					},
					{
						"C ": "Closed"
					},
					{
						"AW": "ArriveWork"
					},
					{
						"GH": "Go Home"
					},
					{
						"OB": "OnBreak"
					},
					{
						"BB": "BackFromBreak"
					},
					{
						"BL": "BackFromLunch"
					},
					{
						"SJ": "Start Job"
					},
					{
						"CJ": "Close Job"
					}
				]
			}
		]
	}
	`;

    return(

<div>
	<pre>
		{html}
	</pre>
</div>
    )};